.container {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: block;
  padding: 0 30px;
}

@media(min-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}

@media(max-width: 1440px) {
  .container {
    max-width: 1176px;
  }
}

@media(max-width: 768px) {
  .container {
    padding: 0 12px;
  }
}