@import '/src/scss/variables.scss';

.single-chart {
  min-width: 48px;
  justify-content: space-around;
}

.circular-chart {
  display: block;
}

.circle-bg {
  fill: none;
  stroke: $grey;
  stroke-width: 2;
}

.circle {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.green .circle {
  stroke: $green;
}

.percentage {
  fill: $font_grey;
  text-anchor: middle;
  font-size: 10px;
}

.no-available {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $grey;
  width: 48px;
  height: 48px;
  font-size: 12px;

  span {
    margin-right: 2px
  }
}

.passed {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $green;
  width: 48px;
  height: 48px;
  font-size: 12px;

  span {
    margin-right: 2px
  }
}