@import '/src/scss/variables.scss';

.footer {
  background: #F9FAFF;

  &__content {
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px;
    color: $font_grey;
    font-size: 14px;

    &__title {
      font-size: 16px;
      color: $dark_grey;
      margin-bottom: 16px;
    }

    &__links {
      margin-top: 16px;
      margin-bottom: 16px;

      a {
        margin-right: 16px;
        color: $font_grey;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__contacts {
      margin-top: 16px;

      a {
        margin-right: 16px;
        color: $font_grey;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__social {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      & a {
        margin-left: 8px;
      }
    }
  }
}
