@import '/src/scss/variables.scss';

.modules {
  padding: 32px 0;

  &-header {
    padding: 16px 24px;
    width: 100%;
    background: $light_blue;
    border: 1px solid $light;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  &__label {
    font-size: 14px;
    line-height: 120%;
    color: $dark_grey;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .success {
    font-weight: 500;
  }

  &-wrap {
    display: flex;
  }

  &-inner {
    max-width: 754px;
    min-width: 754px;
    margin-right: 16px;
  }

  &__description {
    font-size: 14px;
    line-height: 160%;
    color: $light_black;

    h1, h2 {
      font-weight: 600;
      font-size: 22px;
      line-height: 120%;
      color: $light_black;
      margin-bottom: 16px;
    }

    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: $light_black;
      margin: 36px 0 16px;
    }

    h4 {
      margin-top: 36px;
    }

    p, li {
      font-size: 14px;
      line-height: 150%;
      color: $light_black;
      margin-bottom: 16px;
    }

    ul, ol {
      padding-left: 22px;
    }

    li {
      margin-bottom: 12px;
    }

    img {
      max-width: 100%;
      margin-bottom: 36px;
    }
  }

  &-info {
    padding: 24px 24px 32px;
    width: 100%;
    background: $light_blue;
    border: 1px solid $light;
    border-radius: 8px;
    height: fit-content;

    &__text {
      margin-bottom: 20px;
    }

    button {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      color: #fff;
      background: $turquoise;
      height: 40px;
      border-radius: 8px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;

      &:hover {
        background: $turquoise-hover;
      }
    }
  }

  &__progress {
    background: #EEEFF4;
    width: 100%;
    height: 4px;
    margin-bottom: 20px;
    border-radius: 2px;

    &-percent {
      border-radius: 2px;
      height: 100%;
      background: $green;
    }
  }

  iframe {
    margin-bottom: 8px;
    width: 100%;
    height: 200px;
  }

  .free {
    background: $green;

    &:hover {
      background: $green;
    }
  }
}

@media(max-width: 768px) {
  .modules {
    padding: 80px 0;

    &-header {
      padding: 16px 16px 4px;
    }

    &-wrap {
      flex-wrap: wrap;
    }

    &-inner {
      max-width: 100%;
      min-width: 100%;
    }

    &-mobile {
      margin-top: 8px;
    }

    &-info {
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 8px 16px;
      border-radius: 0;
      border: none;
    }
  }
}