@import '/src/scss/variables.scss';

.frame {
  width: 100%;
  background: $light_grey;
  border-radius: 8px;
  display: flex;
  height: 360px;
  margin-bottom: 24px;

  &-content {
    width: 100%;
  }

  &-sidebar {
    width: 181px;
    min-width: 181px;
    max-width: 181px;
    padding: 8px 12px;
    overflow: scroll;

    &__item {
      height: 48px;
      padding: 15.5px 0;
      border-bottom: 1px solid $light;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      color: $font_grey;
      display: flex;
      cursor: pointer;

      img {
        margin-right: 12px;
      }

      svg {
        margin-right: 13px;
      }

      &:hover {
        color: $turquoise;

        svg {
          path {
            fill: $turquoise;
          }
        }
      }

      &--disabled {
        color: $dark_grey !important;

        &:hover {
          color: $dark_grey !important;

          svg {
            path {
              fill: none !important;
            }
          }
        }
      }
    }
  }

  &-question {
    padding: 24px 36px;
    background: #FFFFFF;
    height: 100%;

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 120%;
      text-align: center;
      color: $light_black;

    }

    &-wrap {

    }
  }

  .btn--action {
    max-width: 114px;
    text-align: center;
    margin: 0 auto;
  }

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 8px 0 0 8px;
  }
}

@media(max-width: 768px) {
  .frame {
    flex-wrap: wrap;
    height: 260px;

    &-content {
      height: calc(100% - 40px);
      overflow-x: hidden;
      overflow-y: scroll;
    }

    &-question {
      border-radius: 8px;
    }

    &-sidebar {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      display: flex;
      padding: 15px 30px;
      height: 40px;

      &__item {
        border: none;
        padding: 0;
        height: 10px;
        margin-right: 60px;

        img {
          margin-right: 0;
        }
      }
    }

    iframe {
      border-radius: 8px 8px 0 0;
    }
  }
}
