@import '/src/scss/variables.scss';

.module-card {
  width: 100%;
  background: $light_blue;
  border: 1px solid $light;
  border-radius: 8px;
  padding: 16.5px 13px 8px;
  margin-bottom: 16px;

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 0 12px;

    &-content {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 120%;

      &-wrap {
        color: $dark_grey;
        margin-right: 16px;

        img {
          margin-right: 8.5px;
        }
      }

      p {
        font-weight: 500;
        color: $font_grey;
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: $font_grey;
    margin: 0;
  }

  &__description {
    font-size: 14px;
    line-height: 160%;
    color: $dark_grey;
    padding: 0 40px 0 20px;
    margin-bottom: 9.5px;

    a {
      color: $blue;
    }
  }

  .lessons {
    &__item {
      font-size: 14px;
      line-height: 120%;
      color: $font_grey;
      margin: 0 -13px;
      padding: 0 13px;

      &:hover {
        background: $light;
      }
    }
  }

  .lesson {
    border-top: 1px solid $light;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10.5px 20px 9.5px;

    &__active {
      background: #fff;
      color: $turquoise;
    }
  }
}

@media(max-width: 768px) {

}