@import '/src/scss/variables.scss';

.navigation {
  max-width: 367px;
  width: 100%;
  position: fixed;
  right: 0;
  top: 64px;
  z-index: 1;

  &-wrap {
    padding: 16px 20px 0px;
    background: $light_grey;
    max-height: calc(100vh - 64px);
    overflow: scroll;
  }

  &__item {
    margin-bottom: 24px;
  }

  &__btn {
    padding: 9px;
    width: 44px;
    height: 44px;
    background: $turquoise;
    border-radius: 8px 0px 0px 8px;
    border: none;
    position: absolute;
    left: -45px;
    top: 72px;
  }

  &-desktop-translate {
    transform: translateX(367px);
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    color: $light_black;
    margin-bottom: 26px;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: $light_black;
    margin-left: 12px;
  }

  .lesson {
    &__item {
      font-size: 14px;
      line-height: 120%;
      color: $font_grey;
      margin: 0 -20px;
      padding: 0 20px;

      &:hover {
        background: $light;
      }

      &--is-passed {
        border-top: 1px solid $light;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10.5px 27px 9.5px 33px;

        &__active {
          background: #fff;
          color: $turquoise;
        }
      }
    }
  }
}

@media(max-width: 768px) {
  .navigation {
    max-width: 100%;
    height: 100%;
    top: 0;
    z-index: 12;

    &-wrap {
      height: 100%;
    }

    &__btn {
      display: none;
    }

    &-desktop-translate {
      transform: translateX(100%);
    }

    &-header {
      height: 56px;
      align-items: center;
      justify-content: space-between;

      h3 {
        margin: 0;
      }

      button {
        width: 14px;
        height: 14px;
        background: transparent url('../../assets/icons/close.svg') no-repeat center center;
        border: none;
      }
    }
  }
}