@import '/src/scss/variables.scss';

.lesson-review {
  margin: 0 auto;
  display: block;
  text-align: center;

  h3 {
    color: $font_grey;
    margin-bottom: 12px;
  }

  p {
    color: $font_grey;
    margin-bottom: 24px;
  }

  button {
    padding: 8px 19px;
    border: 1px solid $turquoise;
    border-radius: 4px;
    background: #fff;
  }

  .like {
    margin-right: 16px;
  }

  .dislike {

  }

  .changed {
    background: $turquoise;

    svg {
      path {
        fill: #fff;
      }
    }
  }
}