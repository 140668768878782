@import '/src/scss/variables.scss';

#content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}

.link {
  color: $font_grey;
  margin-left: 24px;
  padding: 27px 8px 19px;
  display: flex;
  align-items: center;
  position: relative;

  svg {
    margin-right: 8px;
  }

  &__active {
    color: $turquoise;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      border-radius: 2px 2px 0px 0px;
      background: $turquoise;
      height: 2px;
      width: 100%;
      bottom: 0;
      left: 0;
    }

    svg {
      path {
        fill: $turquoise;
      }
    }
  }

  &:hover {
    &::before {
      position: absolute;
      content: '';
      border-radius: 2px 2px 0px 0px;
      background: $turquoise;
      height: 2px;
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }
}

.text {
  &__small {
    font-size: 10px !important;
  }
}

.success {
  color: $green !important;
}

.d-flex {
  display: flex;
}

.a-i-c {
  align-items: center;
}

.j-c-c {
  justify-content: center;
}

.j-c-s-b {
  justify-content: space-between;
}

button {
  font-family: 'Inter', sans-serif;
}

.btn {
  &--action {
    color: $turquoise;
    border: 1px solid $turquoise;
    width: 100%;
    height: 36px;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;
    border-radius: 8px;
    background: transparent;
    cursor: pointer;

    &:hover {
      color: #fff;
      background: $turquoise;
    }
  }

  &--primary {
    color: #fff;
    border: none;
    width: 100%;
    height: 36px;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;
    border-radius: 8px;
    background: $turquoise;
    cursor: pointer;

    &:hover {
      background: $turquoise-hover;
    }
  }
}

.answer {
  &-confirmed, &-pending {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: $light_grey;
    padding: 8px 12px;
    background: $succees;
    border-radius: 4px;
    margin-bottom: 24px;
    text-align: center;
  }

  &-pending {
    background: $oringe;
  }

  &-not-confirmed {
    font-size: 13px;
    line-height: 140%;
    color: $light_grey;
    background: $wrong;
    padding: 8px 12px;
    border-radius: 4px;
    margin-bottom: 24px;
  }

  &-answered {
    font-size: 13px;
    line-height: 140%;
    color: $light_grey;
    padding: 8px 12px;
    border-radius: 4px;
    margin-bottom: 24px;
    background-color: #577CDD;
    text-align: center;
  }
}

.error-message {
  color: $error;
  font-weight: normal;
  font-size: 13px;
  line-height: 120%;
  margin: -8px 0 16px;
}

.disabled {
  &--text {
    color: $disabled_text !important;
  }

  &--content {
    color: $dark_grey !important;
  }
}

.input__icon {
  position: absolute;
  transform: translate(-32px, 15px)
}

.modal-open {
  overflow: hidden;
}

.file {
  display: none;
}

.desktop {
  &-flex {
    display: flex !important;
  }

  &-block {
    display: block !important;
  }
}

.mobile {
  &-flex {
    display: none !important;
  }

  &-block {
    display: none !important;
  }
}

@media(max-width: 768px) {
  .desktop {
    &-flex {
      display: none !important;
    }

    &-block {
      display: none !important;
    }
  }
  .mobile {
    &-flex {
      display: flex !important;
    }

    &-block {
      display: block !important;
    }
  }
}
