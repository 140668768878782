@import '/src/scss/variables.scss';

.header-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  color: $font_grey;
  background: rgba(0, 0, 0, 0.12);

  &-bg {
    background: transparent;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .logo {
    margin: 0 auto 26px auto;
    display: block;
    width: 135px;
  }

  &-wrap {
    position: relative;
    z-index: 2;
    max-width: 300px;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 10px 24px;
    font-size: 14px;
    line-height: 120%;
  }

  &-inner {
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-top: 1px solid $light;
    border-bottom: 1px solid $light;
    margin-bottom: 16px;
  }

  &__avatar {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    border-radius: 50%;
  }

  &__name {
    font-weight: 500;
  }

  &__link {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11.5px 0;
    margin-bottom: 8px;
    color: $font_grey;

    &.last {
      padding-bottom: 27.5px;
      margin-bottom: 0;
      border-bottom: 1px solid $light;
      margin-bottom: 16px;
    }
  }

  &__lang {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11.5px 0;
    margin-bottom: 16px;

    .lang {
      margin-right: 0;
    }
  }

  &__logout {
    font-weight: 500;
    border: 1px solid $light;
    border-radius: 8px;
    font-size: 14px;
    color: $font_grey;
    padding: 9.5px 24px;
    margin: 0 auto;
    display: block;
    background: transparent;
  }

  &-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 26px;

    .logo {
      margin: 0;

      &:first-child {
        margin-right: 20px;
        width: 80px;
      }

      &:last-child {
        width: 120px;
      }
    }
  }
}