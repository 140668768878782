@import '/src/scss/variables.scss';

.courses {
  padding: 32px 0;

  &-wrap {
    max-width: 754px;
    width: 100%;
    margin: 0 auto;
  }

  &-no-active-courses {
    font-size: 20px;
  }
}
