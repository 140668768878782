@import '/src/scss/variables.scss';

.lesson {
  padding: 24px 0 100px;

  &-wrap {

  }

  &-inner {
    display: flex;
    margin-bottom: 38px;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: $dark_grey;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: $font_grey;
    margin-bottom: 42px;
  }

  &__link {
    margin-right: 22px;
    width: 36px;
    height: 36px;
    background: $light_grey url('../assets/icons/arrow-grey.svg') no-repeat center center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:hover {
      transform: rotate(-90deg);
      background: $turquoise url('../assets/icons/arrow-white.svg') no-repeat center center;
    }
  }

  &-content {
    max-width: 754px;
    width: 100%;
    margin: 0 auto;

    &__btn {
      width: 100%;
      height: 36px;
    }

    &-wrap {
      background: $light_grey;
      padding: 24px 36px 36px;
      border-radius: 8px;
      margin-bottom: 24px;

      h1, h2 {
        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
        color: $light_black;
        margin-bottom: 16px;
      }

      h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: $light_black;
        margin: 36px 0 16px;
      }

      h4 {
        margin-top: 36px;
      }

      p, li {
        font-size: 16px;
        line-height: 150%;
        color: $light_black;
        margin-bottom: 16px;
      }

      ul, ol {
        padding-left: 22px;
      }

      li {
        margin-bottom: 12px;
      }

      img {
        max-width: 100%;
        margin-bottom: 36px;
      }
    }
  }

  &-desktop-translate {
    transform: translateX(-185px);
  }
}

@media(max-width: 768px) {
  .lesson {
    padding-top: 80px;

    &-content {
      &-wrap {
        padding: 24px 16px 36px;
      }
    }

    &-desktop-translate {
      transform: translateX(0);
    }
  }
}