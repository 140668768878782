@import '/src/scss/variables.scss';

.message-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  background: $modal-bg;
  display: flex;
  align-items: center;
  justify-content: center;

  &-bg {
    background: transparent;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &-close {
    background: url('../../../assets/icons/close.svg') no-repeat center center;
    position: absolute;
    width: 14px;
    height: 14px;
    right: 29px;
    top: 25px;
    z-index: 1;
    cursor: pointer;
  }

  &-wrap {
    position: relative;
    z-index: 2;
    padding: 16px 24px 24px;
    max-width: 558px;
    width: 100%;
    height: 309px;
    background: $light_grey;
    box-shadow: 0px 2px 16px $shadow;
    border-radius: 8px;
  }

  h2 {
    text-align: center;
    margin-bottom: 24px;
  }

  &__select {
    background: #fff url('../../../assets/icons/arrow-down.svg') no-repeat calc(100% - 15px) 50%;
    border: 1px solid $light;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 40px;
    padding: 11px 12px;
    font-size: 14px;
    line-height: 120%;
    color: $light_black;
    border-radius: 4px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  &__textarea {
    border: 1px solid $light;
    border-radius: 4px;
    width: 100%;
    height: 96px;
    display: block;
    outline: none;
    font-size: 14px;
    line-height: 160%;
    padding: 12px;
    color: $light_black;
    margin-bottom: 24px;
    font-family: 'Inter', sans-serif;

    &::placeholder {
      color: $grey_blue;
    }
  }

  &__btn {
    width: auto;
    margin: 0 auto;
  }
}

@media(max-width: 768px) {
  .message-modal {
    &-wrap {
      height: 100%;
      padding: 40px 12px;
      border-radius: 0;
    }
  }
}