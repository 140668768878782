@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '/src/scss/variables.scss';

.presentation {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: $light_black;
  display: flex;
  align-items: center;
  padding: 0 24px;

  &-close {
    background: #313649 url('../../assets/icons/close.svg') no-repeat center center;
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    right: -55px;
    top: 156px;
    z-index: 1;
    cursor: pointer;

    &:hover {
      background: $turquoise url('../../assets/icons/close-white.svg') no-repeat center center;
    }
  }

  div {
    outline: none;
  }

  &-slider__left {
    width: 100px;
    height: 100%;
    margin-right: 27px;

    .slick {
      &-track {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100% !important;
      }

      &-slide {
        margin-bottom: 12px;
        cursor: pointer;
      }

      &-slider, &-list {
        height: 100%;
      }

      &-current {
        border: 2px solid $turquoise;

        div {
          margin-bottom: -0.8px;
        }
      }
    }
  }

  &-slider__right {
    width: 82%;
    height: 100%;
    position: relative;

    .slick {
      &-slide {
        opacity: 0.3;
      }

      &-current {
        opacity: 1 !important;
      }

      &-list {
        height: 100vh !important;
      }

      &-prev {
        top: calc(50% - 24px);
        left: auto;
      }

      &-next {
        top: calc(50% + 24px);
        transform: rotate(180deg);
      }

      &-prev, &-next {
        right: -61px;
        border-radius: 50%;
        height: 36px;
        width: 36px;
        background: $light_grey url('../../assets/icons/arrow.svg') no-repeat center center;

        &:hover {
          background: $turquoise url('../../assets/icons/arrow-white.svg') no-repeat center center;
        }

        &::before {
          content: '';
        }
      }
    }
  }

  &__img {
    // width: 1146px;
    height: 645px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &--mini {
      width: 96px;
      height: 54px;
      background-size: cover;
    }
  }
}

@media(max-width: 768px) {
  .presentation {
    align-items: flex-start;
    padding: 0 12px;

    &-slider__left {
      display: none;
    }

    &-slider__right {
      width: 100%;
      height: 197px;

      .slick {
        &-slide {
          opacity: 1;
        }
      }
    }

    &__img {
      width: 100%;
      height: 197px;
      margin-bottom: 8px;
    }

    &-close {
      right: -4px;
      top: 8px;
    }
  }
}