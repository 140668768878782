@import '/src/scss/variables.scss';

.auth {
  padding-top: 96px;
  padding-bottom: 96px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  &-wrap {
    max-width: 362px;
    width: 100%;
    height: fit-content;
    background: $light_blue;
    border: 1px solid $light;
    box-shadow: 0px 2px 16px $shadow;
    border-radius: 8px;
    padding: 32px 27px 36px;
  }

  &__modal-info {
    position: absolute;
    top: 96px;
    right: 97px;
    border-radius: 8px;
    border: 1px solid $light;
    box-shadow: 0px 2px 16px $shadow;
    background: $light_grey;

    .label {
      max-width: 362px;
      width: 100%;
      padding: 0 32px 26px;
      text-align: center;
    }
  }

  &__modal-bg {
    width: 100%;
    height: 100%;
    position: relative;

    ::before {
      content: '';
      width: 65px;
      height: 72px;
      background: url('../assets/icons/bg1.png') no-repeat;
      position: absolute;
      top: 0;
      left: 0;
    }

    ::after {
      content: '';
      width: 58px;
      height: 34px;
      background: url('../assets/icons/bg2.png') no-repeat;
      position: absolute;
      right: -11px;
      bottom: 24px;
    }
  }

  &__img {
    &-wrap {
      width: 100%;
      padding: 15px 15px 0 0;
      text-align: end;

      img {
        cursor: pointer;
      }
    }
  }

  &-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;

    .link {
      width: 50%;
      display: flex;
      justify-content: center;
      font-size: 16px;
      padding: 0 0 22px 0;
      cursor: pointer;
    }

    &:first-of-type {
      margin-left: -24px;
    }
  }

  .label {
    font-size: 14px;
    line-height: 17px;
    color: $font_grey;
    margin-bottom: 8px;
  }

  input {
    padding: 12px;
    width: 100%;
    background: $grey;
    border: 1px solid $light;
    border-radius: 4px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $light_black;

    &::placeholder {
      color: $dark_grey;
    }
  }

  &__btn {
    background: $turquoise;
    border-radius: 8px;
    border: none;
    padding: 7px 24px;
    font-size: 14px;
    line-height: 160%;
    color: #FFFFFF;
    margin: 24px auto 0 auto;
    display: block;
    cursor: pointer;

    &:hover {
      background: $turquoise-hover;
    }
  }

  &__confirm {
    text-align: center !important;
  }

  &-register {
    .auth__btn {
      margin-bottom: 24px;
    }
  }

  &__link {
    margin-top: -7px;
    font-size: 13px;
    color: $blue;
    display: block;
  }

  &__text {
    font-size: 13px;
    line-height: 140%;
    text-align: center;
    color: $font_grey;

    a {
      color: $blue;
    }
  }

  &-message {
    align-items: center;

    &-wrap {
      p {
        margin-bottom: 10px;
      }

      button {
        margin-top: 0;
      }
    }
  }

  hr {
    width: 100%;
    height: 1px;
    margin-top: 24px;
    border: none;
    background: #DFE2EE;
  }

  &__social-networks-wrap {
    margin-top: 24px;
    text-align: center;
  }

  &__social-networks {
    width: 140px;
    margin: 16px auto 0;
    display: flex;
    justify-content: space-between;
  }
}

@media(max-width: 1375px) {
  .auth {
    &__modal-close-fon {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $dark_transparent;
    }

    &__modal-info {
      max-width: 350px;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      .label {
        max-width: 362px;
        width: 100%;
        padding: 0 32px 15px;
        text-align: center;
      }
    }
  }
}

@media(max-width: 768px) {
  .auth {
    padding: 32px 12px;
  }
}
