@import '/src/scss/variables.scss';

.notification {
  padding: 16px 12px;
  text-align: left;
  border-top: 1px solid #E5E5E5;
  margin: 0 12px;

  &:hover {
    background: $light_blue;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
  }
}
