@import '/src/scss/variables.scss';

.app_wrapper {
  @media (max-width: 510px) {
    padding: 0 12px;
  }
}

//Главный блок, в нем содержится контент для оценки курса и отзыв
.course-review {
  max-width: 754px;
  width: 100%;
  min-height: 606px;
  padding: 0 99px 24px 99px;
  border-radius: 8px;
  border: 1px solid $light;
  background: $light_grey;
  position: relative;
  z-index: 1;
  @media (max-width: 510px) {
    padding: 0 16px 24px;
  }

  &__img__logo { //Блок для картинки сверху, главного блока
    width: 100%;
    min-height: 192px;
    text-align: center;
  }

  &__title { //Текст с поздравлением о пройденном курсе
    width: 100%;
    min-height: 33px;
    font-size: 22px;
    line-height: 150%;
    margin-top: 16px;
    text-align: center;
    font-weight: bold;
    color: $green;
  }

  &__subtitle { //Текст "оцените курс"
    width: 100%;
    min-height: 24px;
    font-size: 16px;
    line-height: 24px;
    margin: 16px auto 0;
    text-align: center;
    color: #5C6075;
  }

  &-wrap {
    padding: 0 14px;
  }

  &__stars__rating { //Блок со звездочками, для оценки курса
    width: 100%;
    min-height: 20px;
    margin-top: 39px;
    display: flex;
    justify-content: space-between;
  }

  &__progress__bar { //Блок с полосой прогресса оценки курса
    width: 100%;
    height: 4px;
    margin: 18.61px auto 0 auto;
    display: flex;
    align-items: center;
    background-color: $grey;

    &--fill {
      background: $turquoise;
      border-radius: 2px;
      height: 100%;
    }
  }

  &__rating__comment { //Текст оценки курса "не плохо!"
    width: 100%;
    min-height: 17px;
    height: 100%;
    font-weight: 500;
    font-size: 14px;
    margin: 16px auto 0 auto;
    text-align: center;
  }

  &__textarea { //Поле для ввода текста комментария
    max-width: 556px;
    width: 100%;
    height: 96px;
    padding: 12px;
    border-radius: 4px;
    margin-top: 36px;
    border: 1px solid $light;
    font-family: 'Inter', sans-serif;
    background-color: #ffffff;

    &::placeholder {
      font-size: 14px;
      color: $grey_blue;
    }
  }

  &__btn { //Кнопка для отправки комментария
    padding: 11.5px 32px;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    margin: 16px auto 0;
    border: none;
    display: block;
    cursor: pointer;
    background: $turquoise;
    color: #fff;
  }

  .star {
    cursor: pointer;

    &-active {
      svg {
        path {
          fill: $turquoise !important;
        }
      }
    }
  }
}


//---------Модальное окно----------

.certificate-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $modal-bg;

  &-close {
    background: url('../../assets/icons/close.svg') no-repeat center center;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    right: 0;
    z-index: 1;
    cursor: pointer;
  }

  &__blockClosed {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  &-wrap {
    position: relative;
    width: 558px;
    height: 249.5px;
    border-radius: 8px;
    padding: 16px 24px 24px;
    background-color: #fff;

    &__title-media {
      display: none;
      @media (max-width: 510px) {
        display: block;
        width: 100%;
        font-size: 22px;
        font-weight: 700;
        margin: 167px 0 36px;
        text-align: center;
        color: #3B3E4B;
      }
    }

    @media (max-width: 510px) {
      padding: 21px 12px 0;
    }

    &__title__buttonClosed {
      display: flex;
      margin-bottom: 32px;
      position: relative;
      @media (max-width: 510px) {
        img {
          position: absolute;
          right: 0;
        }
      }
    }

    @media (max-width: 510px) {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }

    &__title {
      height: 33px;
      font-weight: 700;
      size: 22px;
      line-height: 33px;
      margin: 0 auto;
      color: #3B3E4B;
      @media (max-width: 510px) {
        display: none;
      }
    }

    &-logoLink {
      width: 100%;
      height: 56px;
      display: flex;

      &__logo {
        width: 48px;
        height: 36px;
      }

      &__link {
        padding-left: 12px;
        color: rgba(92, 96, 117, 1);
        width: 100%;

        &__subtitle {
          width: 100%;
        }

        &__link {
          width: 100%;
          height: 24px;
          margin-top: 12px;
          padding: 4px 8px;
          border-radius: 2px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $grey;
          @media (max-width: 510px) {
            button {
              display: none;
            }
          }

          input {
            width: 100%;
            padding-right: 10.6px;
            border: none;
            background-color: $grey;
            color: #5C6075;
          }

          button {
            padding-left: 10.6px;
            border: none;
            color: #577CDD;
            background-color: $grey;
          }
        }
      }
    }

    hr {
      max-width: 486px;
      width: 100%;
      height: 0.5px;
      margin: 24px 0 24px 0;
      border: none;
      background-color: $light;
      @media (max-width: 510px) {
        margin: 45px 0 24px 0;
      }
    }

    &-buttons {
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: space-between;
      @media (max-width: 510px) {
        display: block;
      }

      button {
        width: 243px;
        height: 100%;
        border-radius: 8px;
        padding: 0px 32px;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media (max-width: 510px) {
          width: 100%;
          margin: 20px auto 0;
        }
      }

      &__button__seeCertificate, &__button__seeCertificate-media {
        border: 1px solid $turquoise;
        background: $light_grey;
        color: $turquoise;
      }

      &__button__seeMoreCourse {
        border: none;
        background: $turquoise;
        color: #FFFFFF;
      }
    }
  }

}
