$grey: #EEEFF4;
$dark_grey: #878CA3;
$font_grey: #5C6075;
$dark_transparent: rgba(36, 38, 38, 0.3);
$light_grey: #FCFCFF;
$light_blue: #F9FAFF;
$grey_blue: #BFC3D9;
$disabled_text: #D3D6E1;
$light: #DFE2EE;
$green: #55AE53;
$oringe: #FF7433;
$oringe-hover: #FF7433;
$blue: #577CDD;
$turquoise: #419098;
$turquoise-hover: #419098;
$wrong: rgba(221, 87, 87, 0.72);
$succees: rgba(85, 174, 83, 0.72);
$shadow: rgba(0, 0, 0, 0.08);
$light_black: #3B3E4B;
$error: #DD5757;
$modal-bg: rgba(0, 0, 0, 0.16);