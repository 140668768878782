@import '/src/scss/variables.scss';

.ui-wrap {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 160%;
  color: $light_black;
  margin-bottom: 12px;
}

.radio, .checkbox {
  border: 1px solid $light;
  width: 16px;
  height: 16px;
  max-width: 16px;
  max-height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 10px;
}

.radio {
  border-radius: 50%;
}

.checkbox {
  border-radius: 4px;
}

.answer__active {
  background-color: $turquoise;
  background-repeat: no-repeat;
  border: none;
  background-position: center;
}

.icon {
  display: flex;
  align-items: center;

  &.svg--color {
    svg {
      path {
        fill: $turquoise;
      }
    }
  }
}

.alert {
  position: fixed;
  height: 50px;
  bottom: 32px;
  right: 48px;
  z-index: 12;

  &__text {
    background: $green;
    border-radius: 8px;
    box-shadow: 0px 2px 16px $shadow;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 12px 32px;
  }
}

@media(max-width: 768px) {
  .alert {
    top: 24px;
    left: auto;
    right: auto;
    padding: 0 12px;
  }
}