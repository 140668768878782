@import '/src/scss/variables.scss';

* {
  text-decoration: none;
}

.starContainer {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  margin-bottom: 8px;
  border-bottom: 1px solid $grey;
}

.course-card {
  display: flex;
  padding: 12px 16px 12px 12px;
  background: $light_blue;
  border: 1px solid $light;
  border-radius: 8px;
  max-width: 754px;
  width: 100%;
  margin-bottom: 12px;
  cursor: pointer;
  outline: none;

  &-icon {
    background: $grey;
    border-radius: 8px;
    min-width: 96px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;

    img {
      width: 90px;
    }
  }

  &-content {
    width: 100%;
    margin-right: 18px;

    &__title {
      margin-bottom: 8px;
    }

    &__text {
      color: $font_grey;
      font-size: 14px;
      line-height: 160%;
      border-bottom: 1px solid $grey;
      padding-bottom: 12px;
      margin-bottom: 12px;
    }

    &__price {
      display: inline-block;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      padding: 3.5px 8px;
      color: #FFFFFF;
      background: $turquoise;
      border-radius: 2px;
    }

    &__rating, &__review {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 160%;
      color: #5C6075;
      margin-right: 4px;
    }
  }

  h3 {
    color: $light_black;
  }
}

.bg {
  &-green {
    background: $green;
  }

  &-blue {
    background: $blue;
  }

  &-closed {
    background: $error;
  }
}

.border-none {
  border-bottom: none;
  padding-bottom: 0;
}

@media(max-width: 768px) {
  .course-card {
    h3 {
      font-size: 14px;
    }

    &-content {
      &__text {
        margin-bottom: 8px;
      }
    }

    &-icon {
      display: none;
    }
  }
}
