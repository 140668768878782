@import '/src/scss/variables.scss';

.notifications__root {
  background-color: #FCFCFF;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  color: #333333;

  width: 480px;
  position: absolute;
  top: 80px;
  right: -2px;

  display: none;
  flex-direction: column;

  &__visible {
    display: flex;
  }

  &__title {
    padding: 24px 32px;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }

  &__text {
    padding: 16px 12px;
    border-top: 1px solid #E5E5E5;
  }

  &__list {
    max-height: 300px;
    overflow-y: auto;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    z-index: 10;
    border: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    right: 24px;
    border-bottom: 8px solid $light_grey;
    top: -8px;
  }
}
