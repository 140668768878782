@import '/src/scss/variables.scss';

.lesson-direction {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 72px;
  bottom: 0px;
  background: $light_grey;
  box-shadow: 0px 2px 16px $shadow;

  &-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 447px;
    width: 100%;
    padding: 0 16px;
  }

  &-nav {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: $dark_grey;

    span {
      font-size: 20px;
      color: $font_grey;
    }
  }

  .btn {
    &--action {
      margin: 0;
      width: auto;
    }

    &--primary {
      padding: 0 32px;
      width: auto;
      margin-bottom: 0;
    }
  }

  &--arrow {
    background: $light_grey url('../../assets/icons/arrow-grey.svg') no-repeat center center;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.04);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    border: none;

    &.right {
      transform: rotate(180deg);
    }
  }
}