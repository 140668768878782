@import '/src/scss/variables.scss';

.header {
  height: 64px;
  background: $light_blue;
  box-shadow: 0px 2px 16px $shadow;
  z-index: 10;
  position: sticky;
  top: 0;

  .logo {
    width: 150px;

    &-jas {
      width: 90px;
      margin-right: 12px;
    }
  }

  h3 {
    color: #000;
  }

  &-nav {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &-wrap {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
  }

  &-inner {
    display: flex;
    align-items: center;
  }

  &-user {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    padding: 14px 0;
    border-bottom: 2px solid transparent;

    &__name {
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      margin: 0 12px 0 0;
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .active {
    border-bottom: 2px solid $turquoise;
  }

  &-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 16px;

    .logo {
      &-wrap {
        display: flex;
        align-items: center;
        //position: absolute;
        //left: calc(50% - 92px);
      }

      width: 100px;
    }

    &-menu {
      background: transparent;
      border: none;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__message {
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: $font_grey;
    align-items: center;
    display: flex;
    border: none;
    margin-right: 24px;
    background: transparent;
    padding: 27px 8px 19px;
    border-bottom: 2px solid transparent;

    svg {
      margin-left: 25px;
    }

    &:hover {
      border-bottom: 2px solid $turquoise;
    }
  }

  &__bell {
    border: none;
    border-bottom: 2px solid transparent;
    padding: 16px;
    background: transparent;
    display: flex;
    position: relative;

    &:hover {
      border-bottom: 2px solid $turquoise;
    }

    &__badge {
      position: absolute;
      z-index: 1000;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: $error;
      top: 14px;
      right: 20px;
    }
  }
}

.lang {
  background: transparent;
  border: none;
  margin-right: 32px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: $grey;
  width: 36px;
  height: 28px;
  padding: 5.5px 8px;
  font-size: 14px;
  line-height: 120%;
  color: $light_black;
  border-radius: 4px;
}

.avatar {
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

@media(max-width: 768px) {
  .header {
    height: 56px;

    .logo {
      &-jas {
        width: 75px;
      }
    }
  }

  .lang {
    margin-right: 0;
  }
}
