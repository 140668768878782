@import '/src/scss/variables.scss';

.dropdown {
  position: absolute;
  top: 80px;
  width: 206px;
  background: $light_grey;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  transform: translateX(-44px);
  padding: 0 14px 0 12px;

  &__link {
    color: $font_grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0 12px 0;
    font-size: 14px;
    line-height: 120%;
    width: 100%;

    &:last-child {
      border-top: 1px solid $light;
    }

    &:hover {
      background: $light_blue;
    }
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    z-index: 10;
    border: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    right: 24px;
    border-bottom: 8px solid $light_grey;
    top: -8px;
  }
}
