@import '/src/scss/variables.scss';

.profile {
  padding: 32px 0;

  &-wrap {
    display: flex;
  }

  &-inner {
    max-width: 362px;
    width: 100%;
    margin-right: 30px;
  }

  &-user {
    background: $light_blue;
    border-radius: 5px;
    border: 1px solid $light;
    border-radius: 8px;
    padding: 24px 24px 36px;
    margin-bottom: 24px;

    &__create-modal {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99999;
      width: 100vw;
      height: 100vh;
      background-color: $modal-bg;
    }

    &__avatar {
      border-radius: 50%;
      width: 96px;
      height: 96px;
      display: block;
      margin: 0 auto 24px auto;
    }

    &__label {
      font-size: 14px;
      line-height: 17px;
      color: $dark_grey;
      margin-bottom: 19.5px;
    }

    &__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $light_black;
      margin-bottom: 27.5px;
    }

    &__input {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $light_black;
      margin-bottom: 16px;
      padding: 12px;
      border: 1px solid $light;
      border-radius: 4px;
      width: 100%;
      background: transparent;
    }

    &__btn {
      color: $font_grey;
      font-size: 14px;
      line-height: 160%;
      border: 1px solid $turquoise;
      color: $turquoise-hover;
      border-radius: 8px;
      background: transparent;
      padding: 7px 24px;
      font-weight: 500;
      margin: 0 auto;
      display: block;

      &:hover {
        background: $turquoise-hover;
        color: #fff;
      }

      &--head {
        margin: 0;

      }
    }
  }

  &__link {
    color: $blue;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
  }

  &-edit {
    margin: 0 auto;

    h2 {
      text-align: center;
    }

    p {
      margin-bottom: 8px;
    }

    &-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 24px auto;
      width: 96px;
      height: 96px;

      img {
        margin: 0;
      }

      &__icon {
        position: absolute;
      }

      &::before {
        position: absolute;
        content: '';
        width: 96px;
        height: 96px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50px;
        left: 0;
        top: 0;
      }
    }
  }

  &-courses {
    width: 100%;

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: unset;
        row-gap: 16px;
      }
    }
  }
}

.create-modal {
  &__title {
    text-align: center;

    &--success {
      color: $green;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: $light_black;
    text-align: center;
  }

  &__notification {
    margin-top: 24px;
    text-align: center;
    color: $blue;
  }

  &__close {
    position: absolute;
    top: 25px;
    right: 30px;
    width: 14px;
    height: 14px;
    cursor: pointer;

    &:before, &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #878CA3;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__body {
    position: absolute;
    left: 50%;
    top: 173px;
    transform: translateX(-50%);
    max-width: 572px;
    background-color: $light_grey;
    padding: 16px 122px;
    @media(max-width: 768px) {
      max-width: none;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 40px 16px;
    }

    .profile-user__input {
      background-color: $grey;

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: $grey_blue;
      }
    }

    .profile-user__label {
      color: $font_grey;
      margin-bottom: 8px;
    }
  }

  &__form {
    width: 314px;

    button {
      margin: 0 auto;
    }

    @media(max-width: 768px) {
      width: 100%;
    }
  }

}

@media(max-width: 768px) {
  .profile {
    &-wrap {
      flex-wrap: wrap;
    }

    &-inner {
      max-width: 100%;
      margin: 0 0 16px 0;
    }
  }
}