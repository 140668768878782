@import '/src/scss/variables.scss';

.lessons-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 56px;
  background: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 11;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: $font_grey;
    margin-left: 21px;
  }

  &__btn {
    background: transparent;
    border: none;
  }
}