@import "/src/scss/variables.scss";

.test {
  &__question {
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  &__description {
    font-size: 14px !important;
    line-height: 160% !important;
    color: $font_grey !important;
  }

  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__title {
    margin: 0 !important;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: $dark_grey;
  }

  &-inner {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 24px;
    }
  }

  &__btn {
    width: auto;
    margin: 0 auto;
  }

  .open_ended {
    width: 100%;
    background: #ffffff;
    border: 1px solid $light;
    border-radius: 4px;
    padding: 12px;
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 12px;

    &::placeholder {
      color: $grey_blue;
    }

    &.mentor {
      height: 96px;
      resize: none;
      margin-top: 10px;
    }
  }

  &__label {
    width: 100%;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    color: $font_grey;
    margin-top: 10px;

    span {
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  .answer-not-confirmed {
    text-align: center;
  }
}

.attachment {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  &__control {
    display: flex;
    gap: 12px;

    & .label {
      cursor: pointer;
      max-width: 50%;
      border: 1px dashed #419098;
      border-radius: 4px;
      padding: 16px 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      & p {
        margin: 0;
        font-size: 14px;
        line-height: 1.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &_disabled {
        opacity: 0.5;
      }
    }

    & button {
      padding: 0;
      width: 20px;
      height: 20px;
      font-size: 20px;
      line-height: 20px;
      border: none;
      background: none;
    }
  }

  & input[type="file"] {
    display: none;
  }

  & progress {
    width: 50%;
  }
}
